import React from 'react';
import Image from "../UI/Images/Image";
import {Steps} from "./Steps/Steps";
import "./header.scss";
import {LangSelector} from "../UI/LangSelector/LangSelector";


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: props.step
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props){
            this.setState({currentStep: this.props.step});
        }
    }


    render() {
        const {currentStep} = this.state;
        const {step, setStep, dir, progressStep, onSetToggle} = this.props;
        return (
            <header className={currentStep > 0 ? "transparent" : ""} dir={dir}>
                {currentStep <= 1 ?
                    <Image src={"svg/logo-small.svg"} useLazy={false} className={"logo"}/> :
                    <Image src={"svg/arrow.svg"} useLazy={false} onClick={()=>{setStep(step-1); if (step - 1 < 3) onSetToggle(true);}} className={"back"}/>
                }
                {currentStep > 0 && <Steps stepsAmount={3} setStep={setStep} progressStep={progressStep} currentStep={currentStep}/>}
                <LangSelector onToggleLang={this.props.onToggleLang} dir={dir} className={currentStep > 2 && "hidden"}/>
            </header>
        );
    }


}

export {Header};