import React from 'react';
import "./wallpaper.scss";
import html2canvas from 'html2canvas';
import Image from "../../components/UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

class Wallpaper extends React.Component {
    constructor(props) {
        super(props);
        this.wallpaperRef = React.createRef();
        this.state = {
            size: props.props?.size || {width: 0, height: 0},
            image: props?.props.image || "",
            text: props?.props.text || "",
            renderStarted: false,
            result: null
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                size: this.props.props.size,
                image: this.props.props.image,
                text: this.props.props.text
            }, () => {
                this.updateSizes();
                if (!this.props.preview) this.getWallpaper();
            });
        }
    }

    componentDidMount() {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        window.addEventListener("resize", () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        })
    }

    updateSizes() {
        const size = this.state.size;
        const aspectRatio = size?.height / size?.width;
        if (aspectRatio) {
            const rtl = this.props.dir === 'rtl';
            const styles = aspectRatio >= 1.85 ? {
                "--wallpaperPadding": `${0.09 * size?.height}px ${0.0625 * size?.width}px ${0.125 * size?.height}px `,
                "--h2Size": 0.075 * size?.width + "px",
                "--goalSize": 0.075 * size?.width + "px",
                "--previewGoalSize": document.documentElement.style.getPropertyValue("--previewGoalSize") || 30 + "px",
                "--numberSize": 0.05 * size?.width + "px",
                "--numberBlockSize": 0.06 * size?.width + "px",
                "--h3Size": 0.075 * size?.width + "px",
                "--titleMargin": 0.03 * size?.width + "px",
                "--liSize": 0.053 * size?.width + "px",
                "--dotSize": 0.012 * size?.width + "px",
                "--coverTextTop": 0.056 * size?.width + "px",
                "--coverTextLeft": 0.056 * size?.width + "px",
                "--coverMarginBottom": 0.03 * size?.width + "px",
                "--previewCoverHeight": window.innerWidth - 20 * 365 / 300 + "px"
            } : {
                "--wallpaperPadding": `${0.096 * size?.height}px ${0.052 * size?.width}px ${0.149 * size?.height}px `,
                "--h2Size": 0.06 * size?.width + "px",
                "--goalSize": 0.06 * size?.width + "px",
                "--numberBlockSize": 0.06 * size?.width + "px",
                "--h3Size": 0.06 * size?.width + "px",
                "--previewGoalSize": document.documentElement.style.getPropertyValue("--previewGoalSize") || 25 + "px",
                "--numberSize": 0.05 * size?.width + "px",
                "--titleMargin": 0.03 * size?.width + "px",
                "--liSize": 0.047 * size?.width + "px",
                "--dotSize": 0.012 * size?.width + "px",
                "--coverTextTop": 0.056 * size?.width + "px",
                "--coverTextLeft": 0.056 * size?.width + "px",
                "--coverMarginBottom": 0.03 * size?.width + "px",
                "--previewCoverHeight": (window.innerWidth - 20) * 260 / 290 + "px"
            }

            Object.keys(styles).forEach(key => {
                document.documentElement.style.setProperty(key, styles[key]);
            })

            // if (this.state.text?.goal.length > 20) {
            //     const wallpaperBlock = document.querySelector(this.props.preview ? ".wallpaper.preview" : ".wallpaper.final");
            //     while (wallpaperBlock.querySelector(`.cover`).getBoundingClientRect().height * 0.3 <= wallpaperBlock.querySelector(".text h2").getBoundingClientRect().height) {
            //         const currentVariable = this.props.preview ? "--previewGoalSize" : "--goalSize";
            //         const currentSize = document.documentElement.style.getPropertyValue(currentVariable);
            //         document.documentElement.style.setProperty(currentVariable, currentSize.replace("px", "") - (this.props.preview ? 1 : 4) + "px");
            //     }
            // }
        }
    }


    getWallpaper() {
        let output = "";
        const {text, size, image, renderStarted} = this.state;

        if (size && text && image && !renderStarted) {
            this.setState({renderStarted: true});
            html2canvas(document.querySelector(".wallpaper.final"), {
                scrollY: -window.scrollY,
                width: size.width,
                height: size.height,
                scale: 1
            }).then(
                (canvas) => {
                    const canContext = canvas.getContext("2d");
                    canContext.fillStyle = "#E5DCD3";
                    canContext.fillRect(0, 0, canvas.width, canvas.height);
                    output = canvas.toDataURL();
                    this.setState({renderStarted: false, result: output});
                    if (this.props.callback) this.props.callback(output);
                }
            );
        }
    }


    render() {
        const {className, preview, dir} = this.props;
        const {text, size, image} = this.state;
        return (
            <LanguageContext.Consumer>
                {context => {
                    return (
                        <div className={(`wallpaper ${className || ""} ${preview ? "preview" : "final"}`).trim()}
                             style={!preview ?
                                 {
                                     height: size?.height + "px",
                                     width: size?.width + "px",
                                 }
                                 : {}} dir={dir}>
                            <div className={"title"}>
                                <span>1</span><h2>{context.getTranslate("2021 My Mission")}</h2>
                            </div>
                            <div className={"cover"}>
                                <img src={image || "./mobile/template.jpg"}/>
                                <div className={"text"}>
                                    {/*<h2>*/}
                                    {/*    {text?.headline || "My dream house"}*/}
                                    {/*</h2>*/}
                                    <h2 className={"goal"}>
                                        {text?.goal || " in 10 years for family"}
                                    </h2>
                                </div>

                            </div>
                            <div className={"actions"}>
                                <div className={"heading"}>
                                    <span>2</span>
                                    <h2>{context.getTranslate("Action Plan")}</h2>
                                </div>
                                <ul className={"action-list"}>
                                    {text ? Object.keys(text).forEach((key, index) => {
                                        return key.includes("action") ?
                                            <li key={index}>{text[key]}</li> : null;
                                    }) : null}
                                    {text?.["action#0"] ? <li>
                                        <div className={"dot"}/>
                                        <span>{text?.["action#0"]}</span>
                                    </li> : ""}
                                    {text?.["action#1"] ? <li>
                                        <div className={"dot"}/>
                                        <span>{text?.["action#1"]}</span>
                                    </li> : ""}
                                    {text?.["action#2"] ? <li>
                                        <div className={"dot"}/>
                                        <span>{text?.["action#2"]}</span>
                                    </li> : ""}
                                </ul>
                            </div>
                        </div>
                    )
                }}
            </LanguageContext.Consumer>
        );
    }


}

export {Wallpaper};
