import React from "react";
import ReactFileReader from 'react-file-reader';
import "./fileInput.scss";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Svg from "../SvgSprite/Svg";
import Image from "../Images/Image";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileReaderRef = React.createRef();
    this.state = {
      image: '',
      status: props.input.label,
      fileTypes: this.isFacebookApp() ? '' : [".png", ".jpeg", ".jpg"]
    };
  }

  componentDidMount() {
    if (this.isFacebookApp()) {
      this.fileReaderRef.current.querySelector(".react-file-reader-input").removeAttribute("accept");
    }
  };



  isFacebookApp = () => {
    let ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  };

  inputChange = () => {
    this.setState({ status: "global.form.loading" });
  };

  handleFiles = files => {
    let fileReader = new FileReader();
    fileReader.onload = e => {
      this.setState({
        image: e.target.result,
        status: files.fileList[0].name
      });
    };

    // fileReader.readAsDataURL(files[0]);
    this.props.onChange(this.props.input.id, this.props.input, files.base64);
  };

  render() {

    const { fileTypes, status, image } = this.state;
    const { className = "" } = this.props;

    return (
      <LanguageContext.Consumer>
        {context => {

          return (
            <div className={`file-input ${className} ${this.props.input.hasError ? "has-error" : ""}`} ref={this.fileReaderRef}>
              {/*<input type={"file"} onChange={this.inputChange.bind(this)}/>*/}
              <ReactFileReader beforeChange={this.inputChange}
                               fileTypes={fileTypes}
                               base64={true}
                               multipleFiles={false}
                               handleFiles={this.handleFiles}>
                <div className={'file-preview'}>
                  <div className={'info'}>
                    <Image src={"svg/upload.svg"}/>
                    <span>{context.getTranslate(status)}</span>
                  </div>
                </div>
              </ReactFileReader>
            </div>
          )

        }}
      </LanguageContext.Consumer>
    )
  }
}

export default FileInput;
