import React from 'react';
import {Header} from "../../components/Header/Header";
import Image from "../../components/UI/Images/Image";
import "./hero.scss";
import FormBasic from "../FormBasic/FormBasic";
import formJSON from "../../content/form.json";

const Hero = ({onSuccess, dir}) => {
    return(
        <section className={"pp-section-hero"} dir={dir}>
            <div className={"cover"}>
                <Image src={"mobile/hero/hero.jpg"} useLazy={false}/>
                <h1>
                    Welcome <br/>
                    to Hyundai <br/> Mission <br/> Card Maker
                </h1>
            </div>
            <FormBasic fields={formJSON} onSuccess={onSuccess} dir={dir}/>
        </section>
    )
}

export {Hero};