import React from 'react';
import "./WallpaperPreview.scss";
import {Wallpaper} from "../Wallpaper/Wallpaper";
import Button from "../../components/UI/Button/Button";
import Image from "../../components/UI/Images/Image";
import API from "../../Helpers/API";
import {LanguageContext} from "../../contexts/LanguageContext";

class WallpaperPreview extends React.Component {
// const WallpaperPreview = ({onRestart, step, wallpaper, link = "", email, region, dir, arabic, onToggleLang}) => {
    constructor(props) {
        super(props);
        this.state = {
            sent: false
        };
    }

    sendData = () => {
        const {onRestart, step, wallpaper, link = "", email, region, dir, arabic, onToggleLang} = this.props;
        let file;
        const base = link;
        fetch(base)
            .then(res => res.blob())
            .then(blob => {
                file = new File([blob], "my_mission.png", {type: "image/png"})
                let data = {};
                data.user_id = "test";
                data.email = email;
                data.region = region;
                data.image = file;
                data.model = navigator.platform;
                data.display = `${wallpaper.size.height}x${wallpaper.size.width}`
                data.title = wallpaper.text.headline;
                data.subtitle = wallpaper.text.goal;
                data.first_target = wallpaper.text["action#0"];
                data.second_target = wallpaper.text["action#1"];
                data.third_target = wallpaper.text["action#2"];
                const url = "https://hyundaimea-mymission.com/tools/api/save";
                API(url, data);
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sent} = this.state;
        if (!sent && this.props.link){
            this.sendData();
            this.setState({sent: true});
        }
    }

    render() {
        const {onRestart, step, wallpaper, link = "", email, region, dir, arabic, onToggleLang} = this.props;
        return (
            <LanguageContext.Consumer>
                {context => {
                    if (arabic && context.lang !== 'ar') {
                        onToggleLang(context.lang);
                        context.toggleLang("ar")
                    }
                    ;
                    return (
                        <section className={"pp-section-preview"}>
                            <Wallpaper dir={dir} preview={true} props={wallpaper}/>
                            {link ?
                                <a download={"wallpaper.png"}
                                    // onClick={() => sendData()}
                                   href={`${link}`}>
                                    <Image className={"download"} src={"svg/download.svg"} useLazy={false}/>
                                </a>
                                : null}
                            <Button className={"primary"} text={"btns.new"} onClick={onRestart}/>
                        </section>
                    )
                }}
            </LanguageContext.Consumer>
        );
    }
}

export {WallpaperPreview};