import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useFormikContext} from 'formik';
import * as Yup from 'yup';
import "yup-phone";

import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Select from "../../components/UI/Select/Select";

import "./FormBasic.scss";
import API from "../../Helpers/API";

const FormBasic = ({ fields, region, dir, button, lang, onSuccess }) => {

  const [isBusy, setBusy] = React.useState(false);


    const schemaFields = {};

    Object.keys(fields).map(field => {
        switch (field) {
            case ("firstName"):
                schemaFields[field] = Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Required');
                break;
            case ("lastName"):  schemaFields[field] = Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'); break;
            case ("email"): schemaFields[field] = Yup.string()
                .email('Invalid email')
                .required('Required'); break;
            case ("phone"): schemaFields[field] = Yup.string()
                .min(region === "Jeddah" ? 10 : 1, 'Too Short!')
                .max(region === "Jeddah" ? 10 : 100, 'Too Long!')
                .required('Required'); break;
            default: schemaFields[field] = Yup.string()
                .min(1, 'Too Short!')
                .required('Required')
        }
    });


    const validationSchema = Yup.object().shape(schemaFields);

  const collectMetric = data => {
    const metrics = ["campaign", "sourcee"];
    const baseURL = window.location.search.split("?")?.[1];
    const splitURL = baseURL ? baseURL.split("&") : [];

    splitURL.forEach(url => {
      metrics.forEach(metric => {
        const splitMetric = url.split(`${metric}=`);

        if(splitMetric?.[1]) {
          data[metric] = splitMetric[1]
        }

      })
    });

    return data;
  };


  const collectUTM = data => {
    const UTMS = ["utm_source", "utm_medium", "utm_content", "utm_campaign", "utm_term"];
    const splitURL = window.location.search.split("&");


    if(splitURL[1]) {
      splitURL.forEach(url => {

        UTMS.forEach(utm => {
          const splitUTM = url.split(`${utm}=`);

          if(splitUTM[1]) {
            const utmSplit = utm.split("_");
            utm = utmSplit[0] + utmSplit[1].charAt(0).toUpperCase() + utmSplit[1].slice(1);
            data[utm] = splitUTM[1]
          }

        });

      });
    }

    return data;
  };

  const onSubmit = (values, {setSubmitting, setErrors, setFieldError, setStatus, resetForm, setFieldValue}) => {
    //
    if (values.password === "mymission") onSuccess(values.country, values.email); else {setFieldValue("password", ""); setFieldError('password');};
    return false;
    //
    if(isBusy) return false;
    setBusy(true);
    values.name = region;
    values.region = region;
    values = collectUTM(values);
    values = collectMetric(values);
    let url = `${region}/admin/contacts/`;
    if(region === "Jeddah"){
      url += "jeddah"
    }
    API(url, values, () => {
          setBusy(false);
      resetForm({});
      setStatus({success: true});
      onSuccess();
      // const utm = window.location.search ? window.location.search + `&bank=${bank}` : `?bank=${bank}`;
      // const path = window.location.origin + window.location.pathname;
      //
      // const ending = path.slice(path.length-1, path.length) ;
      // window.location.href = path + `${ending === "/" ? "" : "/"}thank_you`+utm;
    }, (errorData) => {
      let output = false;
      for (let key in values){
        if (values[key] === errorData) {
          // setFieldError(fields[key], "errors.registered")
          fields[key]["errorMessage"] = "errors.registered";
        }
      }
      setStatus({success: false});
      setBusy(false);
    }, "POST",{"Content-Type": "application/json"});
  };

  const defaultValues = {};

  Object.keys(fields).forEach(field => {
    defaultValues[field] = "";
  });

  const setOptions = (target, parent, value, source) => {
    let array = [];
    fields[parent]["options"].forEach(option => {if (option.value === `${value.replace(" ","_")}`){array = option[source]}});
    fields[target].options = array;
    fields[target].value = '';
  }

  const onParentInputChange = (target, field, value, source) => {
    setOptions(target, field, value, source);
  }

  // React.useEffect(()=>{
  //   for (let key in fields){
  //       fields[key]["errorMessage"] = ""
  //   }
  // }, [values])

  return (
    <section className={`pp-section-form ${region || ""}`} dir={dir}>
      <div className={"container"}>
        <Formik
            enableReinitialize
          validationSchema={validationSchema}
          initialValues={defaultValues}

          onSubmit={onSubmit}>
          {
            ({values, errors, touched, setFieldValue }) => (
              <Form dir={dir}>
                <div className={"input-wrapper"}>{
                  Object.keys(fields).map((field, index) => {
                    const input = fields[field];

                    if (input.type === "select") {
                      return <Select field={field}
                                     key={index}
                                     beforeChange={onParentInputChange.bind(this)}
                                     input={input}
                                     errors={errors}
                                     touched={touched} />
                    } else {
                      return <Input field={field}
                                    input={input}
                                    key={index}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue} />
                    }

                  })
                }</div>
                <Button text={button || `btns.start`} className={"white small"} />
              </Form>
            )
          }
        </Formik>
      </div>
    </section>
  );

};

FormBasic.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FormBasic;
