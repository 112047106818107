import React from 'react';
import {CropperComponent} from "../../components/Cropper/Cropper";
import './imageCropSection.scss';
import Button from "../../components/UI/Button/Button";

const ImageCropSection = ({image, callback, onCancel, width, height}) => {

    const cropperRef = React.createRef();


    const onSubmit = async () => {
        let cropped = null;
        cropped = await cropperRef.current.returnResult();
        if (cropped) callback(true, cropped);
    }


    return (
        <section className={"pp-section-cropper"} >
            <h2>Edit your photo</h2>
            <CropperComponent ref={cropperRef} width={width} height={height}
                              imageSRC={image}/>
            <span>
                Move or change <br/>photo scale
            </span>
            <div className={"buttons"}>
                <Button className={"transparent"} onClick={()=>{callback(false)}} text={"btns.cancel"}/>
                <Button className={"primary"} onClick={onSubmit} text={"btns.next"}/>
            </div>
        </section>
    )
}

export {ImageCropSection};