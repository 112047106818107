import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import "./infoInputSection.scss";
import {ImageInfoForm} from "./ImageInfoForm/ImageInfoForm";



const InfoInputSection = ({callback, size, wallpaper, setImage, dir, detectArabic}) => {

    return (
        <LanguageContext.Consumer>
            {context => {return(
                <section className={"pp-section-info"} dir={dir}>
                    <ImageInfoForm detectArabic={detectArabic} onSubmit={callback} setImage={setImage} dir={dir} wallpaper={wallpaper} size={size}/>
                </section>
            ) }}
        </LanguageContext.Consumer>
    )
}
export {InfoInputSection};