import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import {Header} from "../../components/Header/Header";
import {Hero} from "../../layouts/Hero/Hero";
import {FirstStep} from "../../layouts/FirstStep/FirstStep";
import {InfoInputSection} from "../../layouts/InfoInputSection/InfoInputSection";
import {Wallpaper} from "../../layouts/Wallpaper/Wallpaper";
import {WallpaperPreview} from "../../layouts/WallpaperPreview/WallpaperPreview";
import {OrientationBanner} from "../../layouts/OrientationBanner/OrientationBanner";


const Main = ({match}) => {
    const lang = LanguageContext._currentValue.lang;

    const [step, setStep] = React.useState(0);
    const [progressStep, setProgressStep] = React.useState(0);
    const [wallpaper, setWallpaper] = React.useState({
        image: "",
        size: {
            height: "",
            width: ""
        },
        text: {
            "action#0": "",
            "action#1": "",
            "action#2": "",
            goal: "",
            headline: ""
        }
    });


    const [wallpaperImage, setWallpaperImage] = React.useState("");
    const [region, setRegion] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [isArabic, setArabic] = React.useState(false);
    const [dir, setDir] = React.useState("ltr");
    const [prevLang, setPrevLang] = React.useState("en");
    const [langToggle, setLangToggle] = React.useState(false);

    if (step === 0 && localStorage.getItem("region") && localStorage.getItem("email")){
        setEmail(localStorage.getItem("email"));
        setRegion(localStorage.getItem("region"));
        setStep(1);
    }

    React.useEffect(()=>{
        if (step > progressStep) setProgressStep(step);
        if (step < 1) {
            sessionStorage.clear();
        }
    }, [step]);


    const Steps = [
        <Hero onSuccess={(region, email) => {
            localStorage.setItem("region", region);
            localStorage.setItem("email", email);
            setStep(1);
            setEmail(email);
            setRegion(region)
        }} dir={dir}/>,
        <FirstStep dir={dir} wallpaper={wallpaper} callback={(size)=>{setStep(2); wallpaper.size = size;}}/>,
        <InfoInputSection
            wallpaper={wallpaper}
            size={wallpaper.size}
            dir={dir}
            callback={(text, image)=>{setStep(3); wallpaper.text = text; wallpaper.image = image;}}
            setImage={(croppedImage)=>{wallpaper.image = croppedImage;}}
            detectArabic={(arabic)=>{if (isArabic !== arabic) setArabic(arabic);}}
        />,
        <WallpaperPreview
            onToggleLang={(prevLang)=>{setPrevLang(prevLang);}}
            arabic={isArabic}
            dir={isArabic || dir === "rtl" ? "rtl" : dir}
            region={region} email={email}
            step={step}
            onRestart={()=>{setStep(1); setLangToggle(true); sessionStorage.clear(); setProgressStep(1); setWallpaper({});}}
            wallpaper={wallpaper} link={wallpaperImage}/>
    ];

    return (
        <LanguageContext.Consumer>
            {context => {
                if (langToggle && prevLang !== context.lang) { setLangToggle(false); context.toggleLang(prevLang);};
                const direction = context.getTranslate("direction");
                if (context.lang === "ar") {setArabic(true)};
                if (direction !== dir) setDir(context.getTranslate("direction"));
                return (
                    <main className={`pp-page-sales ${dir}`}
                          dir={dir}>
                        <Header onToggleLang={(prevLang)=>{setPrevLang(prevLang)}} onSetToggle={setLangToggle} progressStep={progressStep} step={step} dir={dir} setStep={setStep}/>
                        {Steps[step]}
                        <Wallpaper  dir={isArabic || dir === "rtl" ? "rtl" : dir} className={"hidden"} props={wallpaper} callback={(image)=>{setWallpaperImage(image);}}/>
                        <OrientationBanner/>
                    </main>
                )
            }}
        </LanguageContext.Consumer>
    );

};

Main.propTypes = {};

export default Main;
