import React from 'react';
import {LanguageContext} from "../../../contexts/LanguageContext";
import Image from "../Images/Image";
import "./sizeSelect.scss";

const SizeSelect = ({className, field, onChange}) => {
    const {image, label, type} = field;

    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <div className={`size-select ${className}`} onClick={()=>{onChange(type)}}>
                            <Image useLazy={false} src={image}/>
                            <span>{context.getTranslate(label)}</span>
                    </div>
                );
            }}
        </LanguageContext.Consumer>
    )
}

export {SizeSelect};