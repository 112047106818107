import React from 'react';
import Cropper from 'react-easy-crop';
import "./cropper.scss";
import getCroppedImg from "./CropImage";

class CropperComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            imageSRC : props.imageSRC,
            crop: {x: 0, y: 0},
            zoom: 2,
            aspect: this.props.height / this.props.width >= 1.85 ?  365 / 300 : 260 / 290 ,
            rotation: 0,
            result: null,
            croppedArea: null,
            croppedAreaPixels: null
        };
    }

    onCropChange(crop){
        this.setState({crop});
    }

    async returnResult(){
        let croppedImage = "";
        const getCropped = await getCroppedImg(this.state.imageSRC, this.state.croppedAreaPixels, this.state.rotation).then((value => croppedImage = value));
        return croppedImage;
    }

    onComplete (croppedArea, croppedAreaPixels) {
        this.setState({croppedArea, croppedAreaPixels});
    }


    render() {
        const {imageSRC, crop, zoom, aspect, rotation} = this.state;
        return(
            <div className={"cropper"} style={{height: `calc(100vw * ${aspect} + 40px)`}}>
                <Cropper
                    crop={crop}
                    image={imageSRC}
                    zoom={zoom}
                    maxZoom={10}
                    minZoom={1}
                    cropSize={{
                        width: window.innerWidth - 40,
                        height: (window.innerWidth - 40) * aspect
                    }}
                    rotation={rotation}
                    onCropChange={this.onCropChange.bind(this)}
                    onZoomChange={(zoom)=>{this.setState({zoom})}}
                    onRotationChange={(rotation)=>{this.setState({rotation})}}
                    onCropComplete={this.onComplete.bind(this)}
                />
            </div>
        )
    }

}

export {CropperComponent}