import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import fieldsJSON from "../../content/sizes.json";
import {SizeSelect} from "../../components/UI/SizeSelect/SizeSelect";
import Parser from "html-react-parser";
import "./firstStep.scss";
import Button from "../../components/UI/Button/Button";

const FirstStep = ({callback, wallpaper, dir}) => {


    const returnUserDevice = () => {
        let ios = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
        let android = /(android)/i.test(navigator.userAgent);
        if (ios) return "ios";
        if (android) return "android";
        return "android";
    }
    const getElementByType = (type) => {
        const fields = Array.from(fieldsJSON);
        let output = {};
        fields.forEach(element => {
            if (element.type === type) output = element;
        });
        return output;
    }
    const getScreenResolution = () => {
        const pixelRatio = window.devicePixelRatio;
        return {
            height: Math.round(window.screen.availHeight * pixelRatio),
            width: Math.round(window.screen.availWidth * pixelRatio)
        }
    }
    returnUserDevice();

    const screen = getScreenResolution();
    const [type, setType] = React.useState(returnUserDevice());
    const [active, setActive] = React.useState(false);
    const [options, setOptions] = React.useState(getElementByType(type).options);
    const [customSize, toggleCustomSize] = React.useState(false);
    const [selectedSize, setSelectedSize] = React.useState(screen);
    const selectRef = React.createRef();


    React.useEffect(() => {
        setOptions(getElementByType(type).options);
    }, [type]);


    React.useEffect(() => {
        if (!wallpaper.size && selectedSize) {
            const optionsList = options;
            optionsList.map(option => option.selected = false);
            setOptions(optionsList);
            toggleCustomSize(false);
            setSelectedSize(screen);
            selectRef.current.value = screen;
        }
    }, [wallpaper]);

    const onSelectChange = (event) => {
        setActive(false);
        let value = event.target.value.split(",");
        setSelectedSize({
            height: parseInt(value[0]),
            width: parseInt(value[1])
        });
        const optionsList = options;
        optionsList.map(option => {

            option.selected = option.value.toString() === event.target.value;
        });
        setOptions(optionsList);
    }

    const onExit = () => {
        callback(selectedSize);
    }


    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <section className={"pp-section-first"} dir={dir}>
                        <div className={`detection  ${customSize ? "dimmed" : ""}`} onClick={() => {
                            setSelectedSize(screen);
                            toggleCustomSize(false);
                        }}>
                            <h2>{Parser(context.getTranslate("sizes.title"))}</h2>
                            <div className={"size"}>
                                <span>{context.getTranslate("sizes.subtitle")}</span>
                                <span>
                                    {screen.width}
                                    x
                                    {screen.height}
                                </span>
                            </div>
                        </div>
                        <div className={`selectors ${!customSize ? "dimmed" : ""}`}>
                            <div className={"check"}>
                                <input checked={customSize} onChange={() => {
                                    toggleCustomSize(!customSize)
                                }} id={"customSize"} type={"checkbox"}/>
                                <label htmlFor={"customSize"}>{context.getTranslate("sizes.another")}</label>
                            </div>
                            <div className={"content"}>
                                {fieldsJSON.map((field, index) => {
                                    return (
                                        <SizeSelect
                                            className={field.type === type ? "active" : ""}
                                            field={field}
                                            key={index}
                                            onChange={(size) => {

                                                setType(size);
                                            }}
                                        />
                                    )
                                })}
                                <div className={`select ${active ? "active" : ""} ${!options ? "inactive" : ""}`}>
                                    <select
                                        ref={selectRef}
                                        id={"size-selector"}
                                        onChange={onSelectChange}
                                        onBlur={() => {
                                            setActive(false);
                                        }}
                                        onFocus={() => {
                                            setActive(true);
                                        }}
                                    >

                                        <option value={screen}
                                                disabled
                                                selected={true}>
                                            {context.getTranslate("sizes.defaultOption")}
                                        </option>

                                        {options.map((option, index) => {
                                            if (option.selected && !customSize && wallpaper.size) toggleCustomSize(true);
                                            return (
                                                <option
                                                    value={option.value}
                                                    selected={option.selected}
                                                >
                                                    {context.getTranslate(option.label)}
                                                </option>
                                            )
                                        })}

                                    </select></div>
                            </div>
                        </div>
                        <Button onClick={onExit} className={"primary"} text={"btns.next"}/>
                        {/*{navigator.userAgent} <br/><br/>*/}
                        {/*{Math.round(window.screen.availHeight * window.devicePixelRatio)}*/}
                        {/*X*/}
                        {/* {Math.round(window.screen.availWidth * window.devicePixelRatio)}*/}
                        {/* <br/><br/>*/}
                        {/*{navigator.platform}*/}
                        {/*<br/><br/>*/}
                        {/*{returnUserDevice()}*/}
                    </section>
                )
            }}
        </LanguageContext.Consumer>
    )
}
export {FirstStep};