import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({name, title, svgClass}) => {

	return (
		<svg className={`svg ${svgClass || ""}`}>
			<use xlinkHref={`#${name}`}/>
			<title>{title}</title>
		</svg>
	);

};
Svg.propTypes = {
	name: PropTypes.string.isRequired,
	title: PropTypes.string,
	svgClass: PropTypes.string,
	onClick: PropTypes.func
};
export default Svg;
