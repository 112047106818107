import React from 'react';
import fieldsJSON from "../../../content/wallpaperInfoInputs.json";
import {DefaultInput} from "./inputs/DefaultInput/DefaultInput";
import FileInput from "../../../components/UI/FileInput/FileInput";
import {LanguageContext} from "../../../contexts/LanguageContext";
import Button from "../../../components/UI/Button/Button";
import {ImageCropSection} from "../../ImageCropSection/ImageCropSection";
import Image from "../../../components/UI/Images/Image";


class ImageInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "",
            croppedImage: props.wallpaper.image || "",
            values: {
                // "headline": {...fieldsJSON.headline},
                "goal": {...fieldsJSON.goal},
                "action#0": {...fieldsJSON.action},
                "action#1": {...fieldsJSON.action},
                "action#2": {...fieldsJSON.action}
            },
            fileInput: {...fieldsJSON.image}
        };

    }

    componentDidMount() {
        const values = this.state.values;
        Object.keys(values).forEach(key => {
            values[key].value = sessionStorage.getItem(key) || "";
        })
        this.setState({values});
    }

    returnActionInputs() {
        let inputs = [];
        let fields = this.state.values;
        for (let index = 0; index < 3; index++) {
            const fieldTemplate = Object.create(fieldsJSON.action);
            fieldTemplate.idPostfix = `#${index}`;
            if (index === 0) fieldTemplate.required = true;
            inputs.push(
                <DefaultInput
                    callback={(name, field, value) => {
                        this.onInputChange(name, field, value)
                    }}
                    name={`action#${index}`}
                    field={this.state.values[`action#${index}`] || fieldTemplate}/>
            );
            if (!fields[`action#${index}`]) fields[`action#${index}`] = (fieldTemplate);
        }
        if (fields !== this.state.values) this.setState({values: fields});

        return inputs;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.wallpaper !== this.props.wallpaper) {
            const currentValues = this.state.values;
            Object.keys(currentValues).forEach((key) => {
                currentValues[key].value = this.props.wallpaper.text[key];
            })
            this.setState({values: currentValues});
        }
    }

    onImageUpload(id, input, file) {
        this.setState({image: file});
    }

    onImageCrop(success, file) {
        if (success) {
            this.setState({croppedImage: file});
            this.props.setImage(file);
        } else {
            this.setState({image: null,})
        }
    }

    onInputChange(name, field, value) {
        const values = this.state.values;
        field.value = value;
        values[name] = field;
        sessionStorage.setItem(name, value);


        if (this.testArabic(value)) this.props.detectArabic(true);
        this.setState({values});
    }

    testArabic(text) {
        let arabic = /[\u0600-\u06FF]/;
        return arabic.test(text);
    }

    validate() {
        let validation = true;
        const values = this.state.values;
        let arabic = false;
        Object.keys(values).forEach((key) => {
            const field = values[key];
            if (this.testArabic(field.value)) arabic = true;
            if (field.validator) {
                if (
                    (!!field.validator.maxLength && field.value.length > field.validator.maxLength)
                    ||
                    (!!field.validator.minLength && field.value.length < field.validator.minLength)
                ) {
                    validation = false;
                    field.hasError = true;
                } else {
                    field.hasError = false;
                }
            }
            if (field.required) {
                validation = field.value?.length >= 3;
                field.hasError = field.value?.length <= 3;
            }
        });
        this.props.detectArabic(arabic);
        if (!(values["action#2"].value || values["action#1"].value || values["action#0"].value)) {
            values["action#0"].hasError = true;
            validation = false;
        } else {
            values["action#0"].hasError = false;
        }
        const fileInput = this.state.fileInput;
        fileInput.hasError = !this.state.croppedImage;
        this.setState({values});
        return validation;
    }

    onSubmit() {
        if (this.validate() && this.state.croppedImage) {
            const fields = this.state.values;
            const text = {};
            Object.keys(fields).forEach(key => {
                const field = fields[key];
                text[key] = field.value;
            })
            this.props.onSubmit(text, this.state.croppedImage);
        }
    }

    render() {
        const {image, croppedImage, values, fileInput} = this.state;
        const {size = {width: 1080, height: 1920}, dir} = this.props;
        return (
            <LanguageContext.Consumer>
                {context => {
                    return (
                        <div className={"image-info-form"} dir={dir}>
                            {!image || (image && croppedImage) ? <>
                                    <div className={"input-group"}>
                                        {/*<DefaultInput name={"headline"} callback={(name, field, value) => {*/}
                                        {/*    this.onInputChange(name, field, value)*/}
                                        {/*}} field={values.headline}/>*/}
                                        <DefaultInput name={"goal"} callback={(name, field, value) => {
                                            this.onInputChange(name, field, value)
                                        }} field={values.goal}/>
                                    </div>

                                    <div className={"input-group"}>
                                        {this.returnActionInputs()}
                                    </div>

                                    {!croppedImage ?
                                        <div className={"file-section"}>
                                            <span>{context.getTranslate("inputs.upload")}</span>
                                            <FileInput onChange={this.onImageUpload.bind(this)} input={fileInput}/>
                                        </div> : <div className={"file-section success"} onClick={() => {
                                            this.setState({image: "", croppedImage: ""})
                                        }}>
                                            <span>{context.getTranslate("inputs.done")}</span>
                                            <div className={"success-block"}>
                                                <Image useLazy={false} src={"svg/success.svg"}/>
                                                <span>{context.getTranslate("inputs.uploaded")}</span>
                                            </div>
                                        </div>
                                    }

                                    <Button
                                        className={"primary"}
                                        onClick={this.onSubmit.bind(this)}
                                        text={context.getTranslate("btns.next")}
                                    /></>
                                : null}
                            {image && !croppedImage ?
                                <ImageCropSection
                                    width={size.width}
                                    height={size.height}
                                    image={image}
                                    callback={this.onImageCrop.bind(this)}/> : null}

                        </div>
                    )
                }}
            </LanguageContext.Consumer>
        )
    }
}

export {ImageInfoForm}