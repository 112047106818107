import React from 'react';
import langList from "../../../content/languages.json";
import {LanguageContext} from "../../../contexts/LanguageContext";
import Image from "../Images/Image";
import "./langSelector.scss";



const LangSelector = ({dir, className, onToggleLang}) => {

    const [active, setActive] = React.useState(false);



    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <div className={`lang-selector ${className || ""}`.trim()} dir={dir}>
                        <div className={"visible"} onClick={()=>{setActive(!active)}}>
                            <Image src={"svg/language.svg"} useLazy={false} className={"lang-selector"}/>
                        </div>
                        <div className={`hidden ${active ? "active" : ""}`}>
                            <div className={"triangle"}/>
                            <div className={"list"}>
                            {langList.map((language, index) => {
                                return (
                                    <span className={context.lang === language.value ? "active" : ""}
                                          key={index}
                                          onClick={() => {onToggleLang(context.lang); context.toggleLang(language.value); setActive(false);}}
                                    >

                                          {language.label}

                                    </span>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                )
            }}
        </LanguageContext.Consumer>
    )
}
export {LangSelector};