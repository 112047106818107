import React from 'react';
import PropTypes from 'prop-types';
import './steps.scss';

const Steps = ({stepsAmount, currentStep, setStep, progressStep}) => {

    const createSteps = () => {
        let steps = [];
        for (let index = 1; index <= stepsAmount; index++){
            steps.push(
              <span
                  key={index}
                  className={`step${index <= currentStep ? " active" : ""}${index <= progressStep && index > currentStep ? " visited" : ""}`}
                  onClick={()=>{if (index <= currentStep) setStep(index)}}
              >
                  {index}
              </span>
            );
        }
        return steps;
    }

    React.useEffect(()=>{setSteps(createSteps())}, [currentStep]);

    const [steps, setSteps] = React.useState(createSteps());

    return (
        <div className={"steps"}>
            {steps}
        </div>
    )
}

Steps.propTypes = {
    stepsAmount: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired
}

export {Steps};