import React from 'react';
import {LanguageContext} from "../../../../../contexts/LanguageContext";
import "./defaultInput.scss";

const DefaultInput = ({name, field, callback}) => {
    let {
        id,
        className = "",
        label,
        value = "",
        placeholder = "",
        type = "text",
        validator,
        prefix = "",
        required = false,
        idPostfix = "",
        errorMessage = "inputs.error"
    } = field;

    const [fieldValue, setValue] = React.useState(value);
    const [error, setError] = React.useState(field.hasError);

    React.useEffect(()=>{
        setValue(field.value);
        setError(field.hasError);
    }, [field]);


    const onChange = (event) => {
        const value = event.target.value;
        if (validate(value)){
            setValue(value);
            setError(false);
            if (callback) callback(name, field, value);
        } else {
            setError(true);
        }
    }

    const validate = (value) => {
        let validation = false;
        if (validator?.maxLength) {
            validation = value.length + prefix.length > validator.maxLength;
        }
        return !validation;
    }

    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <div className={`input-default ${className} ${error || field.hasError ? "has-error" : ""}`}>
                        <input
                            type={type}
                            value={prefix + fieldValue.replace(prefix,"")}
                            onChange={(event) => {onChange(event)}}
                            placeholder={context.getTranslate(placeholder)  + " " + idPostfix}
                            id={(id || name) + idPostfix}
                            required={required}
                            name={name  + idPostfix}/>

                        {label ? <label htmlFor={(id || name)  + idPostfix}>{context.getTranslate(label) + " " + idPostfix} </label> : null}
                        {/*{error ? <span className={"error"}>{context.getTranslate(errorMessage)}</span> : null}*/}
                    </div>
                )
            }}
        </LanguageContext.Consumer>
    )
}

export {DefaultInput};